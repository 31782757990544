<template>
  <v-container fluid>
    <v-sheet rounded class="pa-4">
      <v-row>
        <!-- <v-col cols="12" class="d-flex justify-end">
          <v-row>
            <v-col cols="12" sm="8" md="4">
              <FilterList
                :filter="filter"
                @refetch="refetch"
              />
            </v-col>
            <v-col cols="auto">
              <v-btn
                depressed
                color="primary"
                @click="showDialogCreateEditRole"
              >
                Tambah Role
              </v-btn>
            </v-col>
          </v-row>
        </v-col> -->
        <v-col cols="12">
          <TableList
            :filter="filter"
            :key="keyList"
            ref="tableList"
          />
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import TableList from './TableList.vue';
// import FilterList from './FilterList.vue';

export default {
  name: 'page-rejection-orders',
  components: {
    TableList,
    // FilterList,
  },
  data() {
    return {
      keyList: 0,
      filter: {},
    };
  },
  methods: {
    refetch() {
      this.keyList += 1;
    },
  },
};
</script>
