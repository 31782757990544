<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="800"
  >
    <v-card>
      <v-card-title class="caption mb-8">
        <v-row>
          <v-col cols="12">
            <h2>Update Pesanan</h2>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="mt-2">
            <v-col cols="4">
              <label for="originLocationId">{{$_strings.order.TYPE_VEHICLE}}</label>
            </v-col>
            <v-col cols="auto">:</v-col>
            <v-col cols="6" class="pt-0">
              <common-auto-complete-items
                type="listTypeVehicle"
                searchName="name"
                item-value="id"
                item-text="name"
                dense
                outlined
                class="body-2 pt-2"
                clearable
                v-model="transportTypeId"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.TYPE_VEHICLE)]"
                :filter="typeVehicleFilter"
                :items="itemsTypeVehicle"
                @updateItems="updateItemsTypeVehicle"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-expansion-panels accordion>
              <v-expansion-panel
                v-for="(item,i) in items"
                :key="i"
              >
                <v-expansion-panel-header>
                  <v-row>
                    <v-col class="d-flex align-center pl-0">
                      <b> {{ item.companyName }} - {{ item.travelDocumentNo }}</b>
                    </v-col>
                    <v-col cols="12" sm="2" class="d-flex justify-sm-end pb-2">
                      <v-text-field
                        min="0"
                        v-mask="{'alias': 'custom-integer'}"
                        step="1"
                        outlined
                        hide-details
                        dense
                        class="caption pt-2"
                        v-model="item.sequenceNo"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <label for="originLocationId">{{$_strings.order.ORIGIN}}</label>
                    </v-col>
                    <v-col cols="auto">:</v-col>
                    <v-col cols="6" class="pt-0">
                      <common-auto-complete-items
                        id="originLocationId"
                        type="locations"
                        searchName="name"
                        item-value="id"
                        item-text="name"
                        dense
                        outlined
                        class="body-2 pt-2"
                        clearable
                        v-model="item.fastlogOriginId"
                        :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.ORIGIN)]"
                        :filter="locationFilter"
                        :items="itemsLocation"
                        @updateItems="updateItemsLocation"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <label for="destinationLocationId">{{$_strings.order.DESTINATION}}</label>
                    </v-col>
                    <v-col cols="auto">:</v-col>
                    <v-col cols="6" class="pt-0">
                      <common-auto-complete-items
                        id="destinationLocationId"
                        type="locations"
                        searchName="name"
                        item-value="id"
                        item-text="name"
                        dense
                        outlined
                        class="body-2 pt-2"
                        clearable
                        v-model="item.fastlogDestinationId"
                        :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.DESTINATION)]"
                        :filter="locationFilter"
                        :items="itemsLocation"
                        @updateItems="updateItemsLocation"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row
          justify="end"
          class="ma-0"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <v-btn
              color="red"
              class="white--text"
              small
              block
              @click="dialog = false"
            >
              {{$_strings.common.CANCEL}}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-btn
              color="primary"
              class="white--text"
              small
              block
              :loading="isLoading"
              @click="submit"
            >
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { filterDuplicateValues } from '@/helper/commonHelpers';

export default {
  data() {
    return {
      valid: false,
      dialog: false,
      isLoading: false,
      groupId: null,
      transportTypeId: null,
      items: [],
      locationFilter: {
        name: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      typeVehicleFilter: {
        name: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      itemsLocation: [],
      itemsTypeVehicle: [],
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.setDefaultItemsLocation();
      }
      if (!newVal) {
        this.id = null;
        this.form = {};
      }
    },
  },
  methods: {
    setDefaultItemsLocation() {
      const destination = this.items.filter((x) => !!x.fastlogDestinationId).map((i) => ({
        id: i.fastlogDestinationId,
        name: i.destinationLocationName,
      }));
      const origin = this.items.filter((x) => !!x.fastlogOriginId).map((i) => ({
        id: i.fastlogOriginId,
        name: i.originLocationName,
      }));
      this.updateItemsLocation([...destination, ...origin]);
    },
    updateItemsLocation(items) {
      this.itemsLocation = filterDuplicateValues([...this.itemsLocation, ...items]);
    },
    updateItemsTypeVehicle(items) {
      this.itemsTypeVehicle = filterDuplicateValues([...this.itemsTypeVehicle, ...items]);
    },
    validateForm() {
      const hasValue = this.items.every((x) => x.fastlogOriginId && x.fastlogDestinationId);
      if (!this.$refs.form.validate() || !hasValue) {
        this.$dialog.notify.error('Mohon lengkapi form');
        return false;
      }
      return true;
    },
    async submit() {
      if (!this.validateForm()) return;
      try {
        this.isLoading = true;
        const form = this.items.map((x) => ({
          id: x.id,
          fastlogOriginId: x.fastlogOriginId,
          fastlogDestinationId: x.fastlogDestinationId,
          sequenceNo: x.sequenceNo,
        }));
        await this.$_services.rejectedOrders.updateRejectedOrder({ form, groupId: this.groupId, transportTypeId: this.transportTypeId });
        this.$dialog.notify.success('Berhasil');
        this.$emit('fetchData');
        this.dialog = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
