var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{attrs:{"item-key":"groupId","show-expand":"","expanded":_vm.expanded,"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"server-items-length":_vm.totalData,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.pagination=$event},"item-expanded":_vm.fetchDetail},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.createdAt)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function () { return _vm.showDialogUpdateShipment(item); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('ExpandedItemList',{attrs:{"parentMenuId":item.id,"isLoading":item.fetchingDetail,"items":item.child,"colspanLength":_vm.displayedHeader.length},on:{"fetchData":_vm.fetchData}})]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" List Pesanan Tolakan "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)}),_c('DialogEditShipment',{ref:"dialogEditShipment",on:{"fetchData":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }